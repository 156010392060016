import { useNavigation } from "@react-navigation/native";
import { IconButton, Center } from "native-base";
import { AntDesign } from "@expo/vector-icons";

export default function BackButton({ onPress }) {
  return (
    <IconButton
      colorScheme="indigo"
      variant="ghost"
      _icon={{
        as: AntDesign,
        name: "arrowleft",
      }}
      onPress={onPress}
    />
  );
}
