import { useNavigation } from "@react-navigation/native";
import { IconButton } from "native-base";
import { AntDesign } from "@expo/vector-icons";

export default function SignoutButton() {
  const navigation = useNavigation();

  const onLogout = () => navigation.replace("Login");

  return (
      <IconButton
        colorScheme="indigo"
        variant="ghost"
        _icon={{
          as: AntDesign,
          name: "logout",
        }}
        onPress={onLogout}
      />
  );
}
