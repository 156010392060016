import { ScrollView, VStack } from "native-base";
import Card from "./Card";

export default function CardList({ items }) {
  return (
    <ScrollView h="100%">
      <VStack space={4} margin="4">
        {items.map((item, counter) => (
          <Card key={counter} {...item} />
        ))}
      </VStack>
    </ScrollView>
  );
}
