import { useNavigation, useRoute } from "@react-navigation/native";
import {
  IconButton,
  AlertDialog,
  Center,
  Text,
  Flex,
  Button,
  Box,
  Divider,
  View,
} from "native-base";
import React, { useState } from "react";
import { deleteOfferById, getOfferById } from "../services/Offer";
import { AntDesign } from "@expo/vector-icons";
import BackButton from "../components/BackButton";
import Map from "../components/Map";

export default function DetailOffer() {
  const navigation = useNavigation();
  const route = useRoute();
  let { id } = route.params;
  const [offer, setOffer] = useState({});

  React.useEffect(() => {
    if (typeof id === "string") {
      id = parseInt(id);
    }
    setOffer(getOfferById(id));
  }, [id]);

  React.useLayoutEffect(() => {
    navigation.setOptions({
      title: offer.name,
      headerRight: () => <DeleteOfferButton id={id} />,
      headerLeft: () => (
        <BackButton onPress={() => navigation.navigate("Offer")} />
      ),
    });
  });

  const displayData = [
    { name: "Name", value: offer.name },
    { name: "Straße", value: offer.street },
    { name: "Fläche in m²", value: offer.area },
    { name: "Bisherige Nutzung", value: offer.usage },
    { name: "Verfügbarkeit", value: offer.availability },
    { name: "Branchentyp", value: offer.type },
    { name: "ObjektTyp", value: offer.objectType },
    { name: "Bauzustand", value: offer.condition },
    { name: "Räumliche Aufteilung", value: offer.distribution },
    { name: "Infrastrukturelle Ausstattung", value: offer.infra },
    { name: "Künftiges Vorhaben/Sonstiges", value: offer.other },
    { name: "Stellplatzangebot", value: offer.parking },
  ];

  function getMap() {
    if ("position" in offer && offer.position) {
      return (
        <View h="35%" w="100%">
          <Map zoom={16} position={offer.position} isDragableMarker={false} />
        </View>
      );
    } else {
      return <></>;
    }
  }

  return (
    <>
      {getMap()}
      <Flex padding="2">
        {displayData.map((item, index) => (
          <DataForm key={index} {...item} />
        ))}
      </Flex>
    </>
  );
}

function DataForm({ name, value }) {
  if (!value) {
    return <></>;
  } else {
    return (
      <Box>
        <Text bold>{name}</Text>
        <Text>{value}</Text>
        <Divider margin="2" />
      </Box>
    );
  }
}

function DeleteOfferButton({ id }) {
  const navigation = useNavigation();
  const [isOpen, setIsOpen] = React.useState(false);

  const onClose = () => setIsOpen(false);
  const cancelRef = React.useRef(null);

  const onOpen = () => setIsOpen(true);

  function handleDelete() {
    deleteOfferById(id);
    setIsOpen(false);
    navigation.navigate("Offer");
  }

  return (
    <Center>
      <IconButton
        colorScheme="indigo"
        variant="ghost"
        _icon={{
          as: AntDesign,
          name: "delete",
        }}
        onPress={onOpen}
      />
      <AlertDialog
        leastDestructiveRef={cancelRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Eintrag löschen</AlertDialog.Header>
          <AlertDialog.Body>
            Dies wird den Eintrag komplett und unwiderruflich löschen.
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <Button.Group space={2}>
              <Button
                variant="unstyled"
                colorScheme="coolGray"
                onPress={onClose}
                ref={cancelRef}
              >
                Cancel
              </Button>
              <Button colorScheme="danger" onPress={handleDelete}>
                Delete
              </Button>
            </Button.Group>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    </Center>
  );
}
