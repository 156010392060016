import { useNavigation } from "@react-navigation/native";
import { Button, Heading, Input, Flex } from "native-base";
import React from "react";

export default function Signin() {
  const navigation = useNavigation();

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  });

  function login() {
    navigation.replace("Home");
  }

  const commonProps = {
    mx: "3",
    w: "100%",
    maxWidth: "500px",
    margin: "5px",
  };

  //    <Flex w="100%" alignItems="center" padding="5px" justify="center"></Flex>
  return (
    <Flex align="center" justify="center" h="100%" padding="2">
      <Heading {...commonProps}>Kempten Leerstandsmanagement</Heading>
      <Input {...commonProps} placeholder="Benutzername" />
      <Input {...commonProps} placeholder="Passwort" type="password" />
      <Button {...commonProps} onPress={login}>
        Login
      </Button>
    </Flex>
  );
}
