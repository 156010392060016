import { useNavigation } from "@react-navigation/native";
import {
  Button,
  FormControl,
  Input,
  Flex,
  TextArea,
  Select,
  Box,
  Stack,
  View,
  ScrollView,
} from "native-base";
import React from "react";
import Map from "../components/Map";
import { LocationKempten } from "../constants/Locations";
import { addNewOffer } from "../services/Offer";

const commonProps = {
  mx: "3",
  w: "100%",
  maxWidth: "500px",
  margin: "5px",
};

const typeChoices = ["Ladenfläche", "Bürofläche", "Gastronomie", "Sonstige"];
const objectChoices = ["Kaufobjekt", "Mietobjekt", "Sonstiges"];
const conditionChoices = ["Neu", "Gut", "Mittelmäßig", "Sanierungsbedürftig"];

export default function NewOffer() {
  const navigation = useNavigation();
  const [newOfferState, setNewOfferState] = React.useState({
    name: "",
    street: "",
    area: "",
  });

  React.useLayoutEffect(() => {
    navigation.setOptions({
      title: "Füge neues Angebot hinzu",
    });
  });

  function handleNewOffer() {
    console.log(newOfferState);
    addNewOffer(newOfferState);
    navigation.navigate("Offer");
  }

  function getPostitonText() {
    const position = newOfferState.position;
    if (position && "lat" in position && "lng" in position) {
      return position.lat + " " + position.lng;
    } else {
      return "leer";
    }
  }

  return (
    <ScrollView h="100%">
      <Flex align="center" padding="2">
        <View h="15%" w="100%">
          <Map
            zoom={14}
            position={LocationKempten}
            isDragableMarker={true}
            onMarkerChange={(newPosition) =>
              setNewOfferState({ ...newOfferState, position: newPosition })
            }
          />
        </View>
        <FormControl {...commonProps}>
          <Stack space={2.5}>
            <InputText
              name="Position"
              value={getPostitonText()}
              onChange={() => {}}
            />
            <InputText
              name="Name"
              value={newOfferState.name}
              onChange={(text: string) =>
                setNewOfferState({ ...newOfferState, name: text })
              }
            />
            <InputText
              name="Straße"
              value={newOfferState.street}
              onChange={(text: string) =>
                setNewOfferState({ ...newOfferState, street: text })
              }
            />
            <InputText
              name="Fläche in m²"
              value={newOfferState.area}
              onChange={(text: string) =>
                setNewOfferState({ ...newOfferState, area: text })
              }
            />
            <InputTextArea
              name="Bisherige Nutzung"
              value={newOfferState.usage}
              onChange={(text: string) =>
                setNewOfferState({ ...newOfferState, usage: text })
              }
            />
            <InputText
              name="Verfügbarkeit"
              value={newOfferState.availability}
              onChange={(text: string) =>
                setNewOfferState({ ...newOfferState, availability: text })
              }
            />
            <InputSelect
              name="Branchentyp"
              value={newOfferState.type}
              choices={typeChoices}
              onChange={(text: string) =>
                setNewOfferState({ ...newOfferState, type: text })
              }
            />
            <InputSelect
              name="Objektart"
              value={newOfferState.objectType}
              choices={objectChoices}
              onChange={(text: string) =>
                setNewOfferState({ ...newOfferState, objectType: text })
              }
            />
            <InputSelect
              name="Bauzustand"
              value={newOfferState.condition}
              choices={conditionChoices}
              onChange={(text: string) =>
                setNewOfferState({ ...newOfferState, condition: text })
              }
            />
            <InputTextArea
              name="Räumliche Aufteilung"
              value={newOfferState.distribution}
              onChange={(text: string) =>
                setNewOfferState({ ...newOfferState, distribution: text })
              }
            />
            <InputTextArea
              name="Infrastrukturelle Ausstattung"
              value={newOfferState.infra}
              onChange={(text: string) =>
                setNewOfferState({ ...newOfferState, infra: text })
              }
            />
            <InputTextArea
              name="Künftiges Vorhaben/Sonstiges"
              value={newOfferState.other}
              onChange={(text: string) =>
                setNewOfferState({ ...newOfferState, other: text })
              }
            />
            <InputTextArea
              name="Stellplatzangebot"
              value={newOfferState.parking}
              onChange={(text: string) =>
                setNewOfferState({ ...newOfferState, parking: text })
              }
            />
            <Button onPress={handleNewOffer}>Hinzufügen</Button>
          </Stack>
        </FormControl>
      </Flex>
    </ScrollView>
  );
}

function InputText({ name, value, onChange }) {
  return (
    <Box>
      <FormControl.Label>{name}</FormControl.Label>
      <Input
        placeholder={name}
        value={value}
        onChangeText={onChange}
        isFullWidth
      />
    </Box>
  );
}

function InputTextArea({ name, value, onChange }) {
  return (
    <Box>
      <FormControl.Label>{name}</FormControl.Label>
      <TextArea
        placeholder={name}
        value={value}
        onChangeText={onChange}
        isFullWidth
        h={20}
      />
    </Box>
  );
}

function InputSelect({ name, value, onChange, choices }) {
  return (
    <Box>
      <FormControl.Label>{name}</FormControl.Label>
      <Select placeholder={name} onValueChange={onChange}>
        {choices.map((choice, index) => (
          <Select.Item key={index} label={choice} value={choice} />
        ))}
      </Select>
    </Box>
  );
}
