import { useIsFocused, useNavigation } from "@react-navigation/native";
import { IconButton } from "native-base";
import React from "react";
import { getAllOffer } from "../services/Offer";
import CardList from "../components/CardList";
import { AntDesign } from "@expo/vector-icons";
import BackButton from "../components/BackButton";

export default function Offer() {
  const navigation = useNavigation();
  const [allOffer, setAllOffer] = React.useState([]);
  const isFocused = useIsFocused();

  function updateOffer() {
    setAllOffer(getAllOffer());
  }

  React.useEffect(() => {
    updateOffer();
  }, [isFocused]);

  React.useLayoutEffect(() => {
    navigation.setOptions({
      title: "Angebote",
      headerRight: () => <ButtonNewOffer />,
      headerLeft: () => (
        <BackButton onPress={() => navigation.navigate("Home")} />
      ),
    });
  });

  const items = allOffer.map((offer) => {
    return {
      title: offer.name,
      text: offer.street,
      bg: "coolGray.200",
      route: { name: "DetailOffer", params: { id: offer.id } },
    };
  });

  return <CardList items={items} />;
}

function ButtonNewOffer() {
  const navigation = useNavigation();
  return (
      <IconButton
        colorScheme="indigo"
        variant="ghost"
        _icon={{
          as: AntDesign,
          name: "pluscircleo",
        }}
        onPress={() => navigation.navigate("NewOffer")}
      />
  );
}
