import { useNavigation } from "@react-navigation/native";
import React from "react";
import CardList from "../components/CardList";
import SignoutButton from "../components/SignoutButton";

export default function Home() {
  const navigation = useNavigation();

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => <SignoutButton />,
      headerLeft: () => <></>,
    });
  });

  const items = [
    {
      title: "Angebote",
      text: "Angebotene Räumlichkeiten",
      bg: "teal.50",
      route: { name: "Offer" },
    },
    {
      title: "Gesuche",
      text: "Gesuche",
      bg: "teal.100",
      route: { name: "Offer" },
    },
    {
      title: "Gemeldeter Leerstand",
      text: "Gemeldeter Leerstand",
      bg: "teal.200",
      route: { name: "Offer" },
    },
  ];

  return <CardList items={items} />;
}
