export type Offer = { id?: number; name: string; street: string };

let defaultOffer: Offer[] = [
  {
    id: 1,
    name: "FabLab",
    street: "Einhornstraße 42",
    area: "999",
    usage: "happy hacking",
    availability: "jetzt",
    type: "Sonstige",
    objectType: "Sonstiges",
    condition: "Neu",
    distribution: "- Raum 1\n- Raum 2\n- Raum 3",
    infra: "- Toilette\n- Küch...",
    other: "...",
    parking: "unbekannt",
    position: {
      lat: 47.718310587413406,
      lng: 10.311613082885744,
    },
  },
  {
    id: 2,
    name: "Alte Bäckerei",
    street: "Bodmanstraße 22",
    position: {
      lat: 47.718310587413406,
      lng: 10.311613082885744,
    },
  },
  {
    id: 3,
    name: "Einzelzimmer",
    street: "Bodmanstraße 11",
    position: {
      lat: 47.718310587413406,
      lng: 10.311613082885744,
    },
  },
  {
    id: 4,
    name: "Großes Loch",
    street: "Mozartstraße 3",
    position: {
      lat: 47.718310587413406,
      lng: 10.311613082885744,
    },
  },
];

export function getAllOffer() {
  return defaultOffer;
}

export function addNewOffer(offer: Offer) {
  const enrichedOffer = { ...offer, id: defaultOffer.length + 1 };
  defaultOffer.push(enrichedOffer);
}

export function getOfferById(id: number): Offer | undefined {
  return defaultOffer.find((offer) => offer.id === id);
}

export function deleteOfferById(id: number) {
  const index = defaultOffer.findIndex((offer) => offer.id === id);
  defaultOffer.splice(index, 1);
}
