import { useNavigation } from "@react-navigation/native";
import { Box, Heading, Pressable, Text } from "native-base";

export default function Card({ title, text, bg, route }) {
  const navigation = useNavigation();
  const backgroundColor = bg ? bg : "colorGray.100";

  return (
    <Pressable onPress={() => navigation.navigate({...route})}>
      <Box
        bg={backgroundColor}
        rounded="md"
        shadow={3}
        padding="2"
        w="100%"
      >
        <Heading>{title}</Heading>
        <Text>{text}</Text>
      </Box>
    </Pressable>
  );
}
