import React, { useCallback, useMemo, useRef, useState } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
  iconUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
});

export default function Map({ position, isDragableMarker, onMarkerChange, zoom }) {
  return (
    <MapContainer
      style={{ height: "100%", widht: "100%" }}
      center={position}
      zoom={zoom ? zoom : 14}
    >
      <TileLayer
        url="https://tileserver.memomaps.de/tilegen/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      <DraggableMarker
        center={position}
        onMove={onMarkerChange}
        dragable={isDragableMarker}
      />
    </MapContainer>
  );
}

function DraggableMarker({ center, dragable, onMove }) {
  const [position, setPosition] = useState(center);
  const markerRef = useRef(null);
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          setPosition(marker.getLatLng());
          if (onMove) {
            onMove(marker.getLatLng());
          }
        }
      },
    }),
    []
  );

  return (
    <Marker
      draggable={dragable}
      eventHandlers={eventHandlers}
      position={position}
      ref={markerRef}
    ></Marker>
  );
}
